

import {computed, defineComponent, ref, watch} from "vue";
import store from "../../store";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import UserService from "@/core/services/UserService";

export const hours = [
  {value: '1', name: '1AM'},
  {value: '2', name: '2AM'},
  {value: '3', name: '3AM'},
  {value: '4', name: '4AM'},
  {value: '5', name: '5AM'},
  {value: '6', name: '6AM'},
  {value: '7', name: '7AM'},
  {value: '8', name: '8AM'},
  {value: '9', name: '9AM'},
  {value: '10', name: '10AM'},
  {value: '11', name: '11AM'},
  {value: '12', name: '12PM'},
  {value: '13', name: '13PM'},
  {value: '14', name: '14PM'},
  {value: '15', name: '15PM'},
  {value: '16', name: '16PM'},
  {value: '17', name: '17PM'},
  {value: '18', name: '18PM'},
  {value: '19', name: '19PM'},
  {value: '20', name: '20PM'},
  {value: '21', name: '21PM'},
  {value: '22', name: '22M'},
  {value: '23', name: '23M'},
];
export default defineComponent({
  name: "NotificationSetting",
  components: {BaseSelect, BaseForm},
  setup() {
    const submitting = ref(false);
    const profile = computed(() => store.getters.currentUser)
    const model = ref({dailySummary: profile.value.dailySummary, hour: profile.value.hour})
    watch(profile, cb => {
      model.value = {dailySummary: cb.dailySummary, hour: cb.hour}
    })

    const onSave = async () => {
      submitting.value = true;
      await UserService.updateNotification(model.value).then(() => {
      }).finally(() => {
        submitting.value = false;
      })
    }
    return {
      onSave,
      submitting,
      model,
      hours,
    }
  },
})
